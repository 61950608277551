<template>

    <div class="">
      <div v-if="loadingStatus">
        <loading></loading>
      </div>
<div class="min-h-screen  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
  <div class="sm:mx-auto sm:w-full sm:max-w-md">
    <!-- <img class="mx-auto h-12 w-auto" src="https://pbs.twimg.com/profile_images/1051013775598346240/TgD1OjK-.jpg" alt="ADK Hospital"> -->
    <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
      Login to your account
    </h2>
  </div>

  <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
      <form class="space-y-6 login"  @submit.prevent="login" >
        <div>
          <label for="username" class="block text-sm font-medium text-gray-700">
            Username
          </label>
          <div class="mt-1">
            <input id="username" name="username" type="text" v-model="userdata.username" autocomplete="username" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">
            Password
          </label>
          <div class="mt-1">
            <input id="password" name="password" type="password" v-model="userdata.password" autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
          </div>
        </div>

        <div>
          <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Login
          </button>
        </div>
      </form>
    </div>
  </div>
</div>    
</div>
</template>

<script>
import { notify } from "@kyvg/vue3-notification";
import { mapGetters } from "vuex"
import loading from '../components/loading.vue'


export default {
      data(){
      return {
        status: [],
     
        userdata: {
          username: '',
          password: '',
          local_key: '38ced4f2738611255709d6e03bc3duat'
        },
        
      }
      },
      components: { loading },

      
      methods: {
      login: function () {     
   
        const userdata = this.userdata;
        this.$store.dispatch('login', { userdata })

        .then(() => this.$router.push('/'))
        .catch(function (error) {
    if (error.response.status) {

         console.log(error.response);
         notify({
  title: error.response.data.detail,
  type: "error"
});  
    }

  });
        
      },
      
    },
    computed: {
 
        ...mapGetters(["isLoggedIn"]),
          ...mapGetters(["loadingStatus"]),


    }

}
</script>

<style lang="scss" scoped>

</style>